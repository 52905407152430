import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: sessionStorage.getItem('userInfo') ? sessionStorage.getItem('userInfo') : '',
    userAllInfo: sessionStorage.getItem('userAllInfo') ? sessionStorage.getItem('userAllInfo') : '',
    userAllInfoLM: sessionStorage.getItem('userAllInfo') ? sessionStorage.getItem('userAllInfo') : '',
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    tokenLM: localStorage.getItem('tokenLM') ? localStorage.getItem('tokenLM') : '',
    userCompanyId: sessionStorage.getItem('userCompanyId') ? sessionStorage.getItem('userCompanyId') : null,
    selectCompanyId: sessionStorage.getItem('selectCompanyId') ? sessionStorage.getItem('selectCompanyId') : null,
    selectCompanyList: sessionStorage.getItem('selectCompanyList') ? sessionStorage.getItem('selectCompanyList') : [],
    setUserInfoConfigShow: sessionStorage.getItem('setUserInfoConfigShow') ? sessionStorage.getItem('setUserInfoConfigShow') : [],
    customDefaultCoverUrl: sessionStorage.getItem('customDefaultCoverUrl') ? sessionStorage.getItem('customDefaultCoverUrl') : '',
     // 正式
     baseUrl:'http://8.140.20.255:8080',
     baseUrlLM:'http://101.200.96.47:8080',
    //  徐12:8080
    //  baseUrl:'http://192.168.1.27:8080',
     // 测试
      // baseUrl: 'http://8.140.205.126:8080',
     // 沈阳
    //  baseUrl:'http://8.140.48.13:8080',
    // baseUrl:'http://192.168.1.25:8080',
    // baseUrl:'http://192.168.1.18:8080'
    // baseUrl:'http://192.168.1.74:8080',
    // baseUrl:'http://192.168.1.75:8080',
    recordNum:'辽ICP备2023006860号-1', // 备案号
  },

  getters: {
    user: state => {
      return JSON.parse(state.userInfo)
    },

    userAllInfo: state => {
      return JSON.parse(state.userAllInfo)
    },

    token: state => {
      return state.token
    },
    tokenLM: state => {
      return state.tokenLM
    },

    userCompanyId: state => {
      return state.userCompanyId
    },
    selectCompanyId: state => {
      return state.selectCompanyId
    },
    selectCompanyList: state => {
      return state.selectCompanyList
    },
    setUserInfoConfigShow: state => {
      return state.setUserInfoConfigShow
    },
    customDefaultCoverUrl: state => {
      return state.customDefaultCoverUrl ? state.customDefaultCoverUrl : '';
    },
  },
  mutations: {
    // 修改token，并将token存入sessionStorage
    setUserInfo(state, user) {
      state.userInfo = JSON.stringify(user);
      sessionStorage.setItem('userInfo', JSON.stringify(user));
    },
    changeLogout() {
      sessionStorage.removeItem('userInfo');
      localStorage.removeItem("token")
    },
    changeLogoutLm() {
      sessionStorage.removeItem('userInfo');
      localStorage.removeItem("token")
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token)
    },
    setTokenLM(state, tokenLM) {
      state.tokenLM = tokenLM;
      localStorage.setItem("tokenLM", tokenLM)
    },
    setUserCompanyId(state, id) {
      state.userCompanyId = id;
      sessionStorage.setItem('userCompanyId', id);
    },
    setSelectCompanyId(state, id) {

      state.selectCompanyId = id;
      sessionStorage.setItem('selectCompanyId', id);
    },
    setUserAllInfo(state, userAll) {
      state.userAllInfo = JSON.stringify(userAll);
      sessionStorage.setItem('userAllInfo', JSON.stringify(userAll));
    },
    setUserAllInfoLM(state, userAll) {
      state.userAllInfo = JSON.stringify(userAll);
      sessionStorage.setItem('userAllInfoLM', JSON.stringify(userAll));
    },
    setSelectCompanyList(state, selectCompanyList) {
      state.selectCompanyList = selectCompanyList;
      sessionStorage.setItem('selectCompanyList', selectCompanyList);
    },
    setUserInfoConfigShow(state, setUserInfoConfigShow) {
      state.setUserInfoConfigShow = setUserInfoConfigShow;
      sessionStorage.setItem('setUserInfoConfigShow', setUserInfoConfigShow);
    },
    setCustomDefaultCoverUrl(state, customDefaultCoverUrl) {
      state.customDefaultCoverUrl = customDefaultCoverUrl;
      sessionStorage.setItem('customDefaultCoverUrl', customDefaultCoverUrl);
    }
  },
  actions: {

  },
  modules: {
  }
})
