import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/font/font.css'
import service from "@/assets/service";
import serviceLM from "@/assets/serviceLM";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css'
import 'swiper/css/swiper.css'

//导出表格
import FileSaver from 'file-saver';
import * as xlsx from 'xlsx';

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor';
import * as Quill from 'quill'; //引入编辑器
import imageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", imageResize);
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$ajax = service; //token过期刷新token继续请求
Vue.prototype.$ajaxLM = serviceLM;

// oss上传
import oss from './components/common/oss/oss';
Vue.prototype.$oss = oss;


//返回上一个菜单
Vue.prototype.hanleBackBtn = function () {
  router.back();
}

//导出表格
Vue.prototype.exportExcel = function (id) {
  /* generate workbook object from table */
  let wb = xlsx.utils.table_to_book(document.querySelector('#' + id));
  /* get binary string as output */
  let wbout = xlsx.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
  try {
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), id + '_data.xlsx')
  } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
  return wbout
};
//级联最后一级children设置
Vue.prototype.setTreeLeaf = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].children.length < 1 || data[i].children === null) {
      // children若为空数组，则将children设为undefined
      data[i].children = undefined;
    } else {
      // children若不为空数组，则继续 递归调用 本方法
      this.setTreeLeaf(data[i].children);
    }
  }
  return data;
};

// 一键已读(企业页)
Vue.prototype.handleOneClickRead = function (menuId, userEnterpriseId) {
  this.$ajax
    .Get_data(
      `/info/action/menu/saveAllMenuDataRead?menuId=${menuId}&userEnterpriseId=${userEnterpriseId}`
    )
    .then((res) => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        // this.$router.go(0);
        // 刷新当前路由
        const { fullPath } = this.$route;
        this.$router.replace({
          path: '/redirect' + fullPath
        })
      } else {
        this.$message.warning(res.msg);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
// 保存已读（调详情用）
Vue.prototype.saveIsRead = function (data) {
  this.$ajax
    .Post_data("/info/action/menu/saveMenuDataRead", {
      menuId: data.menuId,
      dataId: data.id,
    })
    .then((res) => {
      if (res.code == 200) {
      } else {
        this.$message.error(res.msg);
      }
    })
    .catch((err) => {
      console.log(err);
      this.$message.error("获取数据失败！");
    });
}

// 一键已读（新闻页）
Vue.prototype.handleOneClickReadNews = function (type, newsIdList) {
  console.log(type, newsIdList)
  this.$ajax
    .Post_data(
      'info/readNews/oneClickRead', {
      type: type,
      newsIdList: newsIdList
    }
    )
    .then((res) => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        // this.$router.go(0);
        // 刷新当前路由
        const { fullPath } = this.$route;
        this.$router.replace({
          path: '/redirect' + fullPath
        })
      } else {
        this.$message.warning(res.msg);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

//刷新当前路由
Vue.prototype.goRouterRefresh = function () {
  const { fullPath } = this.$route;
  this.$router.replace({
    path: "/redirect" + fullPath,
  });
}

// 返回顶部
Vue.prototype.goTop = function () {
  document.querySelector("#header").scrollIntoView(true);
}

// 文件下载并改名
Vue.prototype.downLoadFile = function (url, name) { // 下载文件
  download(url, name) // OSS可下载的文件url，你想要改的名字
  function getBlob(url, cb) { // 获取文件流
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (xhr.status === 200) {
        cb(xhr.response)
      }
    }
    xhr.send()
  }
  function saveAs(blob, filename) { // 改名字
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      var link = document.createElement('a')
      var body = document.querySelector('body')

      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      // fix Firefox
      link.style.display = 'none'
      body.appendChild(link)

      link.click()
      body.removeChild(link)

      window.URL.revokeObjectURL(link.href)
    };
  }
  function download(url, filename) { // 执行
    getBlob(url, function (blob) {
      saveAs(blob, filename)
    })
  };
}

// 全局button防抖指令 使用v-debounce
Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)
    })
  }
})


// 获取视频时长
Vue.prototype.getMp4Time = function (file) {
  return new Promise(async (resolve, reject) => {
    let url = URL.createObjectURL(file);
    let audioElement = new Audio(url);
    let durtaion = 0;
    // 下面需要注意的是在监听loadedmetadata绑定的事件中对duration直接进行赋值是无效的，需要在fun回调函数中进行赋值
    audioElement.addEventListener("loadedmetadata", function () {
      //音频/视频的元数据已加载时，会发生 loadedmetadata 事件
      durtaion = audioElement.duration; //时长以秒作为单位
      fun(parseFloat(durtaion).toFixed(1))
    });
    let fun = (s) => {
      console.log(s)
      durtaion = s;
      resolve(durtaion);
    };
  })
}

Vue.prototype.isShowMp4 = async function (file) {
  const duration = await this.getMp4Time(file);
  if (duration * 1 < 60) { // 60s
    return true;
  } else {
    this.$message.warning("上传视频长度不能超过 60 秒");
    return false;
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  }
}).$mount('#app');
